import React, { useContext } from "react"
import Text from '@typo/Text'
import StyledLink from '@typo/StyledLink'
import MainHeading from '@typo/MainHeading'
import styled from 'styled-components'
import headerImage from '@images/articles/erotic-audio-stories/blog-header.svg'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "@components/Seo";
import FAQContent from '@components/schemas/seoAudioPornStories.json'
import Header from "@menus/header"
import { FirebaseContext } from '@components/Firebase'

const ArticleWrapper = styled.div`
  display:flex;
  justify-content: center;
  padding-top:90px;
  @media (min-width: 768px) {
    width:100%;
    padding-top:150px;
  }
`

const ArticleHeaderImage =  styled.img`
/* padding-top:30px; */
`

const ContentPaddingWrapper = styled.div`
  max-width:900px;
  padding: 0px 5% 100px 5%;
`

const FirstText =  styled(Text)`
 &:first-letter {
    text-transform:${props => props.title ? '' : 'uppercase'};
    font-size:${props => props.title  ? '' : '200%'};
    font-family:${props => props.title ? '' : props.theme.mainFontHeading};
    line-height: 1;
  }
  margin-bottom:50px;
`

const MetaData =  styled.p`
  font-family:${props => props.title ? '' : props.theme.mainFontParagraph};
  font-size:0.8rem;
  line-height:0.5;
`
const ListItemWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  padding-bottom:50px;
  align-items: center;

`

const ItemImageWrapper = styled.div`
  width:100%;
  @media (min-width: 768px) {
    width:100%;
  }
`

const ItemTextColumn = styled.div`

  
`

const ItemText = styled.div`
  font-family: ${props => props.theme.mainFontParagraph},'Arial';
  line-height: 1.6;
  h4 {
    margin: 0px;
    font-size: 1.4rem;
  }

  a {
    color: ${props => props.theme.secondaryColor};
  }
`

const ItemCta = styled(Text)`
  font-family:'Poppins';
`
const ItemMetaInfo = styled(Text)`
  line-height:1;
`
const SubHeadline = styled(Text)`
  padding-bottom:10px;
  font-style:italic;
`

const EroticAudio = styled.span`
  font-size:14px; 
  font-family:'Poppins';
  padding-left:3px;
  display: block;
`

const ItemHeadline = styled.h2`
  font-size: 2.5rem;
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
 
  }
`
const FAQItemHeadline = styled.h3`
  font-size: 2rem;
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
  }
`

const HeaderTitle = styled.h2`
  ${props => props.topPadding ? 'padding-top: 100px;' : ''}
  font-size: 3rem;
  ${props => props.center ? 'text-align:center;' : ''}
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
    text-align:center;
  }
`

const content = {
  "summer-rain-threesome":{
    "subtitle":"Romantic Audio Porn:",
    "text":"You’re at a summer party hosted by your friend and her husband. You’re having a good time enjoying the drinks, good food, and dancing. All of a sudden, it starts to rain, hard. You seek the nearest source of shelter, a gazebo tucked away in the corner of the yard, and soon realize your friend’s husband and his very attractive coworker are stranded with you, waiting out the rain, while everyone else is inside. Things very quickly heat up between the three of you during this 14-minute naughty little romance.",
    "rank":10
  },
  "in-the-forest":{
    "subtitle":"Public Sex Audio Porn:",
    "text":"You and your partner want to do something special today and decide to drive until you reach an isolated forest clearing. There’s no one around for miles. You walk for a while until you’re secluded in the forest and your lover tells you to undress, so you do. You’re nervous, but you trust him implicitly, so you obey. He says no one is going to see us...except for one other person. You agree, blindfolding yourself and allowing him to take you deeper into the woods. You, your lover and a mysterious stranger explore each other’s bodies without limits and the woods during this 11-minute steamy audio.",
    "rank":9
  },
  "basement-threesome":{
    "subtitle":"Bondage Audio Porn:",
    "text":"In this steamy threesome story, you stumble into your neighbors having sex in the basement of your apartment building. You watch them for a while, mesmerized at how they explore each other’s bodies. When they spot you, to your delight (and surprise), they invite you to join them. This 8-minute thriller takes you on a wild ride without you ever having to leave your building.",
    "rank":8
  },
  "escoted-male-sex-story":{
    "subtitle":"Romantic Audio Porn:",
    "text":"In this sensual and erotic tale, you treat yourself after an extremely long week of work by hiring an escort to be your companion for the evening. He waits for you outside the fancy hotel, under the dark night sky. You feel nervous, but he immediately soothes your nerves and takes charge. That’s what you wanted, after all, isn’t it? This 23-minute story is perfect for forgetting the stresses of life and relaxing (and indulging) in some sexy self-care.",
    "rank":7
  },
  "caught-cheating":{
    "subtitle":"Teacher-Student Audio Porn:",
    "text":"It’s date-night and you’re stuck at the office. After a long day of work, you come home, ready to make it up to him with lingerie and a good time. Instead, you walk in on your boyfriend cheating on you with another woman. You’re stunned. She’s beautiful. What do you do? You decide to exact your revenge by taking her for yourself and making him watch. This 12-minute bi-sexual tale is sure to get everyone hot under the collar. Listen by yourself and let your fantasy play out or listen with a partner and roleplay - the choice is yours.",
    "rank":6, 
  },
  "road-trip":{
    "subtitle":"Stranger Audio Porn:",
    "text":"You, your partner, and his best friend take a road trip...you have to see where this is going, right? This erotic audio story includes a fun evening at the beach, alcohol, roasting marshmallows by the fire, and sharing yourself with two incredibly sexy lovers. Listening to the crackling of the fire and wondering if you’ll get caught with every rustle of the nearby trees, this 18-minute story is spicy, exciting, and the perfect way to indulge in your threesome fantasies.",
    "rank":5
  },
  "double-pleasure":{
    "subtitle":"Stranger Audio Porn:",
    "text":"You, your partner and their best friend go out for a night on the town. You’re intoxicated by the swanky members club, the strobe lights, and all the beautiful, sweaty bodies surrounding you. This erotic audio is completed with background noises and music. You can hear people talking and dancing all around you as you explore each other’s bodies. Dancing, kissing, touching...who knows where the night might lead. This 18-minute threesome erotic audio story is perfect for exploring what it would be like to be shared by your partner and another man.",
    "rank":4
  },
  "massage-with-benefits":{
    "subtitle":"Teacher Student Audio Porn:",
    "text":"After booking a deluxe room in one of the world’s most exclusive hotels (surrounded by the beautiful Swiss Alps), you decide to indulge in a massage. The resort is known for its spa and sauna, after all. The small towel provided is the only barrier between you and the gorgeous masseur, and things quickly heat up. Told from the point of view of the masseur, this 13-minute story is perfect for when you’re in the mood to be appreciated and doted upon.",
    "rank":3
  },
  "the-king":{
    "subtitle":"Romantic Phone Sex Audio Porn:",
    "text":"Your side job (babysitting) just became a lot more interesting. The husband of a couple you babysit for, who you find very attractive, returns early from a party without his wife. You can’t deny the chemistry between you and what eventually ensues is passionate sex and an affair neither of you will ever forget. The thrill of forbidden desire and the erotic nature of your encounter will have you fantasizing about him long after you’ve gone home. This 14-minute sizzler will get your heart racing in the best possible ways.",
    "rank":2
  },
  "your-boss":{
    "subtitle":"Romantic Friends-to-Lovers Audio Porn:",
    "text":"You might be his boss in the boardroom but in the hotel room, you’re equals. In this steamy erotic audio story, you and your male coworker can’t seem to deny the sexual tension between you. Things escalate from naughty text messages and videos to a late-night rendezvous in a hotel. This 16-minute story is perfect for when you’re feeling naughty and mischievous, with the perfect amount of sensuality sprinkled in.",
    "rank":1
  },
}


const articleSchema = {
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "headline": "Top 10 Audio Porn Stories You Absolutely Need to Listen to in 2021",
  "image": [
    "https://audiodesires.com/images/erotic-audio-stories/top-10-audio-porn-stories-audiodesires-1-1.jpg",
    "https://audiodesires.com/images/erotic-audio-stories/top-10-audio-porn-stories-audiodesires-4-3.jpg",
    "https://audiodesires.com/images/erotic-audio-stories/top-10-audio-porn-stories-audiodesires-16-9.jpg"
   ],
  "datePublished": "2020-12-09",
  "dateModified": "2020-12-09",
  "isAccessibleForFree":true,
  "author": {
    "@type": "Person",
    "name": "Jaimee Bell"
  },
   "publisher": {
    "@type": "Organization",
    "name": "Audiodesires",
    "logo": {
      "@type": "ImageObject",
      "url": "https://audiodesires.com/images/logos/audiodesires_black.png"
    }
  },
  "mainEntityOfPage":{
    "@type":"WebPage",
    "@id":"https://audiodesires.com/erotic-audio-stories/"
 }
}

const EroticAudioStories = (props) => {
  const {user} = useContext(FirebaseContext);
  const lang = props.pageContext.lang

  const convertArrayToObject = (array, key, content) => {
    const initialValue = [];
    return array.reduce((obj, item) => {
      return [...obj,{...content[item.en[key]],...item}]
    }, initialValue);
  };
  const Stories =  convertArrayToObject(props.data.allStory.nodes, 'slug', content)

  return <>
  <Header boxLayout={false} playerMode={'small'} user={user} alwaysShow={true}/>
  <ArticleWrapper>
    <Seo 
      title={"Top 10 Audio Porn Stories to listen to in 2021"}
      description={"Erotic audio stories for women ✅ Professional audio erotica ✅ Different stories and fantasies ✅ 5-20 min ✅ Free stories available ✅ Respectful & relatable"}
      structuredData={[articleSchema, FAQContent]}
    />

    <ContentPaddingWrapper>  
      <MainHeading>{articleSchema.headline}</MainHeading>
      <SubHeadline>Relax, listen, climax - it’s that easy. </SubHeadline>
      <MetaData>Last Updated on December 9, 2020 </MetaData>
      <MetaData>by {articleSchema.author.name} </MetaData>
      <ArticleHeaderImage src={headerImage} alt="Audio Porn by Audiodesires"/>
      <FirstText>
        Audio porn stories allow you to explore your deepest desires in a way that is fun and effortless. Instead of watching someone else experience pleasure, you (and your partner) can enjoy steamy, erotic audio porn stories that bring your wildest fantasies to life.
        <br/><br/>
        Here you will find various types of stories, from soft and sensual to bondage and fetishes. All of our stories are made for women and couples to explore together or separately. Our professional narrators allow you to get lost in your fantasy and explore a world entirely your own. Our stories incorporate real sounding sexual cues like moaning, heavy breathing and kissing to ensure you have a completely immersive and pleasurable experience. 
        <br/><br/>
        Below are our current 10 audio porn stories. These rankings are created based on ratings they’ve been given by listeners just like you. 
      </FirstText>
      {Stories.sort((a,b) =>  b.rank - a.rank ).map((story) => (
        <ListItemWrapper key={story.rank}>
          <ItemImageWrapper>
            <Link to={`/story/${story.en.slug}`}>
              <GatsbyImage
                image={story.localImage.childImageSharp.gatsbyImageData}
                alt={`${story.en.title} - Erotic Audio Story by Audiodesires`} />
            </Link>
          </ItemImageWrapper>
          <ItemTextColumn>
            <ItemHeadline><EroticAudio>{story?.subtitle}</EroticAudio>{story?.rank}. {story.en.title}</ItemHeadline>
            <ItemMetaInfo>Narrator: <StyledLink to={`/voice/${story[lang].voices[0]?.name.toLowerCase()}`}>{story[lang].voices[0]?.name}</StyledLink></ItemMetaInfo>
            <ItemText dangerouslySetInnerHTML={{ __html: content[story.en.slug]?.text}}/>
            <ItemCta>Listen to <StyledLink to={`/story/${story.en?.slug}`}>{story.en?.title}</StyledLink>{} now.</ItemCta>
          </ItemTextColumn>
        </ListItemWrapper>
      
      ))}
      <HeaderTitle center >Frequently Asked Questions (FAQ) about Erotic Audio Stories</HeaderTitle>
      {FAQContent.mainEntity.map((edge,index) =>(
        <ListItemWrapper key={index}>
          <ItemTextColumn>
          <FAQItemHeadline>{edge.name}</FAQItemHeadline>
          <ItemText dangerouslySetInnerHTML={{ __html: edge.acceptedAnswer.text}}/>
          </ItemTextColumn>
        </ListItemWrapper>
      ))}
    </ContentPaddingWrapper>
  </ArticleWrapper>
</>;
}

export default EroticAudioStories
export const query = graphql`{
  allStory(
    filter: {en: {slug: {in: ["summer-rain-threesome", "in-the-forest", "basement-threesome", "escoted-male-sex-story", "caught-cheating", "road-trip", "double-pleasure", "massage-with-benefits", "babysitter-affair", "your-boss"]}}}
  ) {
    nodes {
      ...seoBlogPostStoryInfo 
    } 
  }
}
`